<template>
  <div>

    <!-- Main Row -->
    <div class="row">

      <!-- Form -->
      <div class="col-md-12">
        <Card :title="'Tambah Harga Lab'">
          <template v-slot:body>
            <Form
              :form="form"
              :route="'2-lab-procedures'"
            />
          </template>
        </Card>
      </div>

    </div>

  </div>
</template>

<script>

import Card from "@/view/content/Card.vue"
import Form from '@/component/lab-procedures-2/Form.vue'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"

export default {

  data() {
    return {
      form: {
        name: '',
        price: '',
        description: '',
        lab_category_id: '',
        doctor_commission: '',
        hospital_income: '',
      },
    }
  },

  components: {
    Card,
    Form
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Pemeriksaan Lab", route: "" },
      { title: "Daftar Harga Lab", route: "/lab-procedure-2/list" },
      { title: "Tambah" },
    ])
  },

}

</script>